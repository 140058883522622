<template>
  <div class="phbb-container">
    <FrsRouterBackLink target="fieldDashboard">
      <template #text>
        <IxRes>phbb.buttons.backToDashboard</IxRes>
      </template>
    </FrsRouterBackLink>

    <div class="phbb-header">
      <h3>
        <IxRes>phbb.header.phBB</IxRes>
      </h3>
    </div>

    <div class="info-container">
      <BsCheckbox v-model="showStepHints" inline>
        <IxRes>phbb.wizard.showInfo</IxRes>
      </BsCheckbox>
    </div>

    <div class="card-container">
      <CollapsibleCard :is-collapsed="showStepHints">
        <template #title>
          <IxRes>phbb.wizard.stepOneTitle</IxRes>
        </template>
        <IxRes>phbb.wizard.stepOneHint</IxRes>
        <template #content="">
          <IxButton @click="startInterpolation">
            <IxRes>phbb.wizard.stepOneAction</IxRes>
          </IxButton>
        </template>
      </CollapsibleCard>

      <CollapsibleCard :is-collapsed="showStepHints">
        <template #title>
          <IxRes>phbb.wizard.stepTwoTitle</IxRes>
        </template>
        <IxRes>phbb.wizard.stepTwoHint</IxRes>
        <template #content>
          <IxButton @click="launchOrderSelection">
            <IxRes>phbb.wizard.stepTwoAction</IxRes>
          </IxButton>
        </template>
      </CollapsibleCard>

      <CollapsibleCard :is-collapsed="showStepHints">
        <template #title>
          <IxRes>phbb.wizard.stepThreeTitle</IxRes>
        </template>
        <IxRes>phbb.wizard.stepThreeHint</IxRes>
        <template #content>
          <IxButton @click="startProbingResult">
            <IxRes>phbb.wizard.stepThreeAction</IxRes>
          </IxButton>
        </template>
      </CollapsibleCard>

      <CollapsibleCard :is-collapsed="showStepHints">
        <template #title>
          <IxRes>phbb.wizard.stepFourTitle</IxRes>
        </template>
        <IxRes>phbb.wizard.stepFourHint</IxRes>
        <template #content="">
          <IxButton @click="startCalibrateSensorData">
            <IxRes>phbb.wizard.stepFourAction</IxRes>
          </IxButton>
        </template>
      </CollapsibleCard>

      <CollapsibleCard :is-collapsed="showStepHints">
        <template #title>
          <IxRes>phbb.wizard.stepFiveTitle</IxRes>
        </template>
        <IxRes>phbb.wizard.stepFiveHint</IxRes>

        <template #content>
          <div class="content">
            <span>
              <IxRes>phbb.wizard.label.partOne</IxRes>:
            </span>
            <IxButton @click="startCaORequiredNutrient">
              <IxRes>phbb.wizard.stepFiveAction.partOne</IxRes>
            </IxButton>
            <span>
              <IxRes>phbb.wizard.label.partTwo</IxRes>:
            </span>
            <IxButton @click="startCaOApplication">
              <IxRes>phbb.wizard.stepFiveAction.partTwo</IxRes>
            </IxButton>
            <span>
              <IxRes>phbb.wizard.label.partThree</IxRes>:
            </span>
            <IxButton @click="startLimeApplication">
              <IxRes>phbb.wizard.stepFiveAction.partThree</IxRes>
            </IxButton>
          </div>
        </template>
      </CollapsibleCard>
    </div>
  </div>
</template>

<script>
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import CollapsibleCard from '@components/card/CollapsibleCard'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import IxButton from '@components/IxButton'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

export default {
  components: {
    CollapsibleCard,
    BsCheckbox,
    FrsRouterBackLink,
    IxButton
  },
  mixins: [
    MirrorRouteMixin
  ],
  data () {
    return {
      showStepHints: false
    }
  },
  methods: {
    startProbingResult () {
      this.$router.push({name: 'probingResults', params: {...this.$route.params}})
    },
    startCaORequiredNutrient () {
      this.$router.push({name: 'createCaORequiredNutrient', params: {...this.$route.params}})
    },
    startCaOApplication () {
      this.$router.push({name: 'createCaOApplication', params: {...this.$route.params}})
    },
    startLimeApplication () {
      this.$router.push({name: 'createLimeApplication', params: {...this.$route.params}})
    },
    startInterpolation () {
      this.$router.push({name: 'interpolateSensorData', params: {...this.$route.params}})
    },
    startCalibrateSensorData () {
      this.$router.push({name: 'calibrateSensorData', params: {...this.$route.params}})
    },
    launchOrderSelection () {
      this.$router.push({name: 'orderAdministration', params: {...this.$route.params}})
    }
  }
}
</script>

<style lang="scss" scoped>
.phbb-container {
  margin: 4px;

  .info-container,
  .phbb-header {
    margin: 4px;
    padding-bottom: 4px;
  }
}
.content {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-row-gap: 5px;
}
</style>
